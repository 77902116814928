
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import type { ButtonColor, ButtonSize } from '@/definitions/shared/types'

type Row = {
  rowName?: string
  title?: string
  class?: string
  color?: ButtonColor
  icon?: string
  iconOnly?: boolean
  textLink?: boolean
  flat?: boolean
  noBorder?: boolean
  styleRow?: Record<string, string>
}

export default defineComponent({
  components: {
    TmButton,
    TmScrollbar,
  },
  setup() {
    const sizes: ButtonSize[] = ['xSmall', 'small', 'medium', '', 'large', 'xLarge', 'xxLarge']
    const rows: Row[] = [
      {
        rowName: 'Primary',
        title: 'Create',
        color: 'primary',
        icon: 'edit',
      },
      {
        rowName: 'Blue outline',
        title: 'Blue',
        color: 'blue-outline',
        icon: 'edit',
      },
      {
        rowName: 'Secondary',
        title: 'Import',
        color: 'secondary',
        icon: 'upload',
      },
      {
        rowName: 'Default',
        title: 'Default',
        styleRow: {
          background: '#dbefff',
        },
      },
      {
        rowName: 'Flat',
        title: 'Flat',
        flat: true,
        styleRow: {
          background: '#dbefff',
        },
      },
      {
        rowName: 'No border',
        title: 'No border',
        noBorder: true,
        styleRow: {
          background: '#dbefff',
        },
      },
      {
        rowName: 'Transparent',
        title: 'Transparent',
        color: 'transparent',
        flat: true,
        styleRow: {
          background: '#dbefff',
        },
      },
      {
        rowName: 'Transparent Note',
        title: 'Note',
        color: 'transparent-note',
        flat: true,
        styleRow: {
          background: '#FFF3C8',
        },
      },
      {
        rowName: 'Transparent Dark',
        title: 'Note',
        color: 'transparent-dark',
        flat: true,
        styleRow: {
          background: '#4E4B66',
        },
      },
      {
        rowName: 'Success',
        title: 'Success',
        color: 'success',
      },
      {
        rowName: 'Destructive',
        title: 'Close account',
        color: 'error',
      },
      {
        rowName: 'Orange',
        title: 'Buy credit',
        color: 'warning',
        icon: 'shopping_cart',
      },
      {
        rowName: 'Orange 500',
        title: 'Add note',
        color: 'orange500',
      },
      {
        rowName: 'Yellow',
        title: 'Load note',
        color: 'yellow',
      },
      {
        rowName: 'Icon button',
        icon: 'filter_alt',
        iconOnly: true,
      },
      {
        rowName: 'Icon transparent button',
        color: 'transparent-border',
        icon: 'settings',
        iconOnly: true,
        flat: true,
      },
      {
        rowName: 'Primary link',
        title: 'Primary link',
        textLink: true,
        class: 'primary--text',
      },
      {
        rowName: 'Secondary link',
        title: 'Secondary link',
        textLink: true,
        class: 'secondary--text',
      },
      {
        rowName: 'Error link',
        title: 'Error link',
        textLink: true,
        class: 'error--text',
      },
      {
        rowName: 'Success link',
        title: 'Success link',
        textLink: true,
        class: 'success--text',
      },
      {
        rowName: 'Warning link',
        title: 'Warning link',
        textLink: true,
        class: 'warning--text',
      },
    ]

    const rowsSizes: Row[] = [
      {
        rowName: 'Primary',
        title: 'Create',
        color: 'primary',
        icon: 'edit',
      },
      {
        rowName: 'Icon button',
        icon: 'filter_alt',
        iconOnly: true,
      },
      {
        rowName: 'Primary link',
        title: 'Primary link',
        textLink: true,
        class: 'primary--text',
      },
    ]

    const colors: Row[] = [
      {
        color: 'shade',
        title: 'shade',
        flat: true,
      },
      {
        color: 'transparent-light',
        title: 'transparent-light',
        flat: true,
      },
      {
        color: 'blueish',
        title: 'blueish',
        flat: true,
      },
    ]

    return {
      sizes,
      rowsSizes,
      rows,
      colors,
    }
  },
})
